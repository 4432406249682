<!--
File: ConditionEditFrom.vue
Description: form for adding/editing a single Condition data (IRI, rutting, etc.).
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <md-tabs class="md-success" md-alignment="left">
          <md-tab class="md-success" id="tab-location" :md-label="$t('label.section_location')">
            <div class="md-layout md-size-50">
              <div class="md-layout-item md-small-size-100 md-size-30">
                <RegionsDropdown :label="$t('label.select_region')" v-model="selected_region"
                  :initial_value="selected_region" @input='onRegionChange' />
              </div>
              <div class="md-layout-item md-small-size-100 md-size-30">
                <RoadsDropdown :label="$t('label.select_road')" v-model="selected_road" :initial_value="selected_road"
                  :region_id="selected_region" />
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-30">
                <SectionsDropdown
                  :class="[{ 'md-valid': !errors.has('fk_section') }, { 'md-error': errors.has('fk_section') }]"
                  :label="$t('label.select_section')" v-model="condition.fk_section" data-vv-name="fk_section"
                  v-validate="modelValidations.fk_section" :initial_value="condition.fk_section"
                  :region_id="selected_region" :road_id="selected_road" :is_required="true" @input='onSectionChange' />
              </div>
              <div class="md-layout-item md-small-size-100 md-size-30">
                <md-field :class="[{ 'md-valid': !errors.has('hs_id') }, { 'md-error': errors.has('hs_id') }]">
                  <label>{{ $t('label.hs_id') }}</label>
                  <md-input v-model="condition.hs_id" type="number" data-vv-name="hs_id"
                    v-validate="modelValidations.hs_id" required />
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-30">
                <md-field
                  :class="[{ 'md-valid': !errors.has('start_distance') }, { 'md-error': errors.has('start_distance') }]">
                  <label>{{ $t('road_network.start_distance') }}</label>
                  <md-input v-model="condition.start_distance" type="number" data-vv-name="start_distance"
                    v-validate="modelValidations.start_distance" required />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-30">
                <md-field
                  :class="[{ 'md-valid': !errors.has('end_distance') }, { 'md-error': errors.has('end_distance') }]">
                  <label>{{ $t('road_network.end_distance') }}</label>
                  <md-input v-model="condition.end_distance" type="number" data-vv-name="end_distance"
                    v-validate="modelValidations.end_distance" required />
                </md-field>
              </div>
            </div>
          </md-tab>

          <md-tab id="tab-survey" :md-label="$t('route.survey')">
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field>
                  <label>{{ $t('condition.survey_date') }}</label>
                  <md-input v-model="condition.survey_date" type="string" disabled />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field
                  :class="[{ 'md-valid': !errors.has('fk_interval') }, { 'md-error': errors.has('fk_interval') }]">
                  <label>{{ $t('condition.fk_interval') }}</label>
                  <md-input v-model="condition.fk_interval" type="number" data-vv-name="fk_interval" />
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field :class="[{ 'md-valid': !errors.has('direction') }, { 'md-error': errors.has('direction') }]">
                  <label>{{ $t('inventory.direction') }}</label>
                  <md-input v-model="condition.direction" type="number" data-vv-name="direction" />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field :class="[{ 'md-valid': !errors.has('iri') }, { 'md-error': errors.has('iri') }]">
                  <label>{{ $t('condition.iri') }}</label>
                  <md-input v-model="condition.iri" type="number" data-vv-name="iri" v-validate="modelValidations.iri"
                    required></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field :class="[{ 'md-valid': !errors.has('rut_left') }, { 'md-error': errors.has('rut_left') }]">
                  <label>{{ $t('condition.rut_left') }}</label>
                  <md-input v-model="condition.rut_left" type="number" data-vv-name="rut_left"
                    v-validate="modelValidations.rut_left" required />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field :class="[{ 'md-valid': !errors.has('rut_right') }, { 'md-error': errors.has('rut_right') }]">
                  <label>{{ $t('condition.rut_right') }}</label>
                  <md-input v-model="condition.rut_right" type="number" data-vv-name="rut_right"
                    v-validate="modelValidations.rut_right" required />
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field
                  :class="[{ 'md-valid': !errors.has('cracking_m2') }, { 'md-error': errors.has('cracking_m2') }]">
                  <label>{{ $t('condition.cracking_m2') }}</label>
                  <md-input v-model="condition.cracking_m2" type="number" data-vv-name="cracking_m2"
                    v-validate="modelValidations.cracking_m2" required />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field
                  :class="[{ 'md-valid': !errors.has('potholes_no') }, { 'md-error': errors.has('potholes_no') }]">
                  <label>{{ $t('condition.potholes_no') }}</label>
                  <md-input v-model="condition.potholes_no" type="number" data-vv-name="potholes_no"
                    v-validate="modelValidations.potholes_no" required />
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field :class="[{ 'md-valid': !errors.has('repairs_m2') }, { 'md-error': errors.has('repairs_m2') }]">
                  <label>{{ $t('condition.repairs_m2') }}</label>
                  <md-input v-model="condition.repairs_m2" type="number" data-vv-name="repairs_m2"
                    v-validate="modelValidations.repairs_m2" required />
                </md-field>
              </div>
              <div class="md-layout-item md-small-size-100 md-size-20">
                <md-field
                  :class="[{ 'md-valid': !errors.has('edge_break_m2') }, { 'md-error': errors.has('edge_break_m2') }]">
                  <label>{{ $t('condition.edge_break_m2') }}</label>
                  <md-input v-model="condition.edge_break_m2" type="number" data-vv-name="edge_break_m2"
                    v-validate="modelValidations.edge_break_m2" required />
                </md-field>
              </div>
            </div>
          </md-tab>
        </md-tabs>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import Swal from 'sweetalert2'
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import RoadsDropdown from '../Dropdowns/RoadsDropdown.vue'
  import SectionsDropdown from '../Dropdowns/SectionsDropdown.vue'

  export default {
    name: 'condition-edit-form',
    props: {
      oper: String
    },

    components: {
      RegionsDropdown,
      RoadsDropdown,
      SectionsDropdown,
    },

    data() {
      return {
        cond_id: null,
        selected_region: null,
        selected_road: null,
        section_desc: null,
        condition: {},

        modelValidations: {
          //fk_section: { required: true },
          start_distance: { required: true, numeric: true },
          end_distance: { required: true, numeric: true },
          hs_id: { required: true, numeric: true },
          iri: { required: true, },  // numeric: true },
          rut_left: { required: true, },  // numeric: true },
          rut_right: { required: true, },  // numeric: true },
          cracking_m2: { required: true, numeric: true },
          potholes_no: { required: true, numeric: true },
          repairs_m2: { required: true, numeric: true },
          edge_break_m2: { required: true, numeric: true },
        }

      }
    },

    created() {
      const { cond_id = null } = this.$route.params
      this.cond_id = cond_id
      if (this.oper === 'upd' && this.cond_id) {
        this.$store.dispatch('LOAD_CONDITION_BY_ID', this.cond_id).then((res) => {
          this.condition = Array.isArray(res) ? res[0] : res

          this.$store.dispatch('GET_REGION_ROAD_FROM_SECTION', this.condition.fk_section).then(
            (res) => {
              console.log(res)
              //const section = Array.isArray(res) ? res[0] : res
              this.selected_region = res.fk_region
              this.selected_road = res.fk_road
            },
            (err) => {
              this.selected_region = 0
              this.selected_road = 0
            }
          )
        })
      }
    },

    methods: {
      onClose() {
        this.$store.dispatch('SET_USE_HISTORY')
        this.$router.push('/condition_data/condition/')
      },
      onRegionChange() {
      },
      onRoadChange() {
      },
      onSectionChange() {
      },
      async validate() {
        this.$validator.validateAll().then((isValid) => {
          const alert = {
            type: 'error',
            text: this.$t(`route.condition_data`) + this.$t(`messages.cond_was_not_saved`),
            footer: ''
          }
          if (isValid) {
            // *** delete the rows below after Andrey resolves it in the back-end or move to the api side
            this.condition.hs_id = Number(this.condition.hs_id)
            this.condition.start_distance = Number(this.condition.start_distance)
            this.condition.end_distance = Number(this.condition.end_distance)
            this.condition.iri = Number(this.condition.iri)
            this.condition.rut_left = Number(this.condition.rut_left)
            this.condition.rut_right = Number(this.condition.rut_right)
            this.condition.cracking_m2 = Number(this.condition.cracking_m2)
            this.condition.potholes_no = Number(this.condition.potholes_no)
            this.condition.repairs_m2 = Number(this.condition.repairs_m2)
            this.condition.edge_break_m2 = Number(this.condition.edge_break_m2)
            // *** end delete

            const payload = this.oper === 'add' ? this.condition : { id: this.cond_id, condition: this.condition }
            if (this.oper !== 'add') {
              delete payload.condition.data_id
            }

            this.$store.dispatch(`${this.oper.toUpperCase()}_CONDITION`, payload).then(
              () => {
                alert.type = 'success'
                alert.text = this.$t('route.condition_data') + this.$t(`messages.cond_was_saved`)
                Swal.fire(alert).then(() => {
                  this.$nextTick(() => this.$validator.reset())
                  return
                })
              },
              (err) => {
                alert.footer = err
              }
            )
            //this.$router.push('/condition_data/condition/')
          } else {
            /* alert.text += "\nNumber of fields with errors: " + this.errors.items.length
            this.errors.items.forEach(element => {
              alert.footer += element.field
            })*/
            alert.footer = this.$t('messages.errors_count', { count: this.errors.items.length })
            console.log(this.errors.items)
          }
          Swal.fire(alert)
        })
        this.onClose()
      },
    },
    watch: {
    },
    computed: {
    }
  }
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>
  